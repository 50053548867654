


























































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  getCharacter,
  getCharacterColor,
  getCornerMarkColor,
} from "@/utils/common.util";

@Component({})
export default class NewDestinyCard extends Vue {
  @Prop({ default: "" })
  private readonly id!: string;

  @Prop({ default: 5 })
  private readonly character!: number;
  @Prop()
  type!: number;
  @Prop()
  private readonly product_bazaar_price!: number;

  @Prop({ default: 110 })
  private readonly wideHigh!: number;

  @Prop({ default: true })
  private readonly showExplodeRate!: boolean;

  @Prop({ default: "" })
  private readonly explodeRate!: string;

  @Prop({ default: "0.00" })
  private readonly price!: string;

  @Prop({ default: "" })
  private readonly cover!: string;

  @Prop({ default: "" })
  private readonly title!: string;

  @Prop()
  private readonly rootStyle!: any;

  @Prop({ default: ".8" })
  private readonly opacity!: string;

  @Prop({ default: false })
  private readonly projection!: boolean;

  @Prop({ default: false })
  private readonly dense!: boolean | any;

  @Prop({ default: false })
  private readonly test!: boolean;

  @Emit()
  handleClick(e: any): any {
    return e;
  }

  get bodyStyle(): Record<string, string | number> {
    return {
      "--opacity": this.opacity,
    };
  }

  get characterText(): { icon: string; text: string } {
    const text = getCharacter(this.character, "", true);
    const icon =
      this.character < 5 ? "" : `__character_peak_${this.character - 4}_icon`;
    return {
      text,
      icon,
    };
  }

  get coverStyle(): Record<string, string> {
    const wh = this.wideHigh + "px";
    return {
      height: wh,
    };
  }

  get rootTopStyle(): Record<string, string> {
    return {
      "--before-card-color": `var(--card-color-${this.character})`,
    };
  }

  get characterStyle() {
    const dense = this.dense !== false;
    return {
      ...getCharacterColor(this.character, true),
      width: this.wideHigh + 2 + "px",
      height: 78 + this.wideHigh + 10 - (dense ? 6 : 0) + "px",
      "--dense-scale": dense ? ".7" : "1",
      ...this.rootStyle,
    };
  }

  get getCharacterCornerMarkColor(): Record<string, string> {
    return {
      ...getCornerMarkColor(this.character),
      display: this.character === -1 ? "none" : "",
    };
  }
}
