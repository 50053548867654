









































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  getCharacter,
  getCharacterColor,
  getCornerMarkColor,
  simpleStrToSeconds,
  countdown,
  getNow
} from "@/utils/common.util";

@Component({})
export default class DestinyNewCard extends Vue {
  @Prop({ default: "" })
  private readonly id!: string;
  timer = '00时00分00秒'
  @Prop({ default: 5 })
  private readonly character!: number;

  @Prop({ default: 110 })
  private readonly wideHigh!: number;

  @Prop({ default: true })
  private readonly showExplodeRate!: boolean;

  @Prop({ default: "" })
  private readonly explodeRate!: string;

  @Prop({ default: "0.00" })
  private readonly price!: string;
  @Prop({ default: "-" })
  private readonly product_price!: number;

  @Prop({ default: "" })
  private readonly cover!: string;

  @Prop({ default: "" })
  private readonly title!: string;
  @Prop({ default: "" })
  private readonly type!: string;

  @Prop()
  private readonly rootStyle!: any;

  @Prop({ default: ".8" })
  private readonly opacity!: string;

  @Prop({ default: false })
  private readonly projection!: boolean;

  @Prop({ default: false })
  private readonly dense!: boolean | any;

  @Prop({ default: false })
  private readonly test!: boolean;

  @Prop({ default: "" })
  private readonly duration!: string;
  @Emit()
  handleClick(e: any): any {
  }
  tagList = [
    {
      img: "https://cdn.vgn.cn/static/new/coupon0.png",
    },
    {
      img: "https://cdn.vgn.cn/static/new/excellent0.png",
    },
    {
      img: "https://cdn.vgn.cn/static/new/elips.png",
    },
    {
      img: "https://cdn.vgn.cn/static/new/epic.png",
    },
    {
      img: "https://cdn.vgn.cn/static/new/legend.png",
    },
    {
      img: "https://cdn.vgn.cn/static/new/myth.png",
    },
    {
      img: "https://cdn.vgn.cn/static/new/myth.png",
    },
    {
      img: "https://cdn.vgn.cn/static/new/myth.png",
    },
    {
      img: "https://cdn.vgn.cn/static/new/myth.png",
    },
    {
      img: "https://cdn.vgn.cn/static/new/myth.png",
    },
  ];
  get bodyStyle(): Record<string, string | number> {
    return {
      "--opacity": this.opacity,
    };
  }

  get characterText(): { icon: string; text: string } {
    const text = getCharacter(this.character, "", true);
    const icon =
      this.character < 5 ? "" : `__character_peak_${this.character - 4}_icon`;
    return {
      text,
      icon,
    };
  }

  get coverStyle(): Record<string, string> {
    const wh = this.wideHigh + "px";
    return {
      height: wh + 2,
    };
  }

  get rootTopStyle(): Record<string, string> {
    return {
      "--before-card-color": `var(--card-color-${this.character})`,
    };
  }

  get characterStyle() {
    const dense = this.dense !== false;
    return {
      // ...getCharacterColor(this.character, true),
      "border-radius": "6px",
      width: this.wideHigh + 20 + "px",
      height: this.wideHigh + 20 - (dense ? 6 : 0) + "px",
      "--dense-scale": dense ? ".7" : "1",
      "background-image": `var(--card-color-image-${this.character})`,
      ...this.rootStyle,
    };
  }
  destroy() {
    clearInterval(this.times)
  }
  times:any = ''
  created() {
    if(this.character<0){
      let timer = simpleStrToSeconds(this.duration) + getNow()
      this.times = setInterval(()=>{
        let list = countdown(timer)
        this.timer = `优惠剩余时间：${list.day}天${list.hour}时${list.minute}分${list.second}秒`
      },1000)
    }
  }
  get getCharacterCornerMarkColor(): Record<string, string> {
    return {
      ...getCornerMarkColor(this.character),
      display: this.character === -1 ? "none" : "",
    };
  }
}
