














import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SynFooter extends Vue {
  @Prop({ default: false })
  private readonly disabled!: boolean;

  @Prop({ default: "" })
  private readonly str!: string;

  @Emit("click")
  showTips() {}
}
