














































import { Component, Ref, Vue } from "vue-property-decorator";
import PrizeGroup from "./components/PrizeGroup.vue";
import DestinyReward from "@/views/destiny-draw/components/destinyReward/DestinyReward.vue";
import SupplyGroup from "./components/SupplyGroup.vue";
import { groupBy, map, cloneDeep, uniq, fromPairs } from "lodash";
import { Dictionary } from "vue-router/types/router";
import { SelectData } from "./type";
import { Tab, Tabs, Toast } from "vant";
import SynthesisTip from "./components/SynthesisTip.vue";
import SynFooter from "./components/SynFooter.vue";
import SynFate from "./components/SynFate.vue";
import MessageTip from "@/components/MessageTip.vue";
import { TYPE_TEXT } from "@/constant";
import {
  IMineSupply,
  IPrize,
  VoucherApi,
  VoucherRecordStatus,
} from "@/api/voucher.api";
import {syntheticUpgrade,getMineSupply} from "@/api/user-supply.api"
import { delay, observer, setBackgroundColor } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {
    SynFate,
    SynFooter,
    SynthesisTip,
    SupplyGroup,
    DestinyReward,
    PrizeGroup,
    MessageTip,
    Tabs,
    Tab,
  },
})
export default class SyntheticV3 extends Vue {
  @Ref("messageTip")
  private readonly messageTip!: MessageTip;

  @Ref("destiny-reward-home")
  private readonly destinyRewardRef: DestinyReward | undefined;

  @Ref("supply-groups")
  private readonly supplyGroupsRef!: SupplyGroup[];

  @Ref("syn-fate")
  private readonly synFateRef!: SynFate;

  tips = false;
  loading = true;
  active = 0;
  category = ["全部", ...TYPE_TEXT].map((str, i) => ({
    str,
    key: i - 1,
    show: false,
  }));
  prizeItems: IPrize[] = [];
  showCharacter: number[] = [];
  supplyItems: Dictionary<IMineSupply[]> = {};
  supplyLength = 0;
  supplyItemLength: Dictionary<number> = {};
  previewData: IPrize[] = [];

  currentSelect = {
    select: [] as string[],
    character: -1,
    need_num: 0,
  };

  tabChange() {
    window.scrollTo(0,0) 
    this.currentSelect = {
      select: [] as string[],
      character: -1,
      need_num: 0,
    };
    this.supplyGroupsRef.map((row) => row.clear());
  }

  async showTips() {
    if (this.isNotSupply) {
      this.$router.push({ name: "Welfare" });
      return;
    }
    const { character } = this.currentSelect;
    this.previewData = cloneDeep(this.prizeItems).filter(
      (row) => row.need_character === Number(character)
    );
    if (!this.previewData.length) {
      Toast("没有奖品");
      return;
    }
    await this.$nextTick();
    this.tips = true;
  }

  get tabCharacter() {
    // return this.active - 1;
    return this.category.filter((item) => item.show)[this.active].key;
  }

  get btnText() {
    const { select, need_num } = this.currentSelect;
    const num = select.length;
    const select_text = `(${num}/${need_num})`;
    if (this.isNotSupply) return "马上去抽";
    if (!num) return `选择同品质补给进行合成`;
    return `选择同品质补给进行合成${select_text}`;
  }

  get btnDisabled() {
    const { select, need_num } = this.currentSelect;
    if (this.isNotSupply) return false;
    if (!need_num) return true;
    if (!select.length) return true;
    return select.length !== need_num;
  }

  get notDisabledCharacter(): number {
    const { select, character } = this.currentSelect;
    if (!select.length) return -1;
    return character;
  }

  get isNotSupply() {
    const { supplyLength, supplyItemLength, tabCharacter } = this;
    if (!supplyLength) return true;
    if (tabCharacter === -1) return false;
    return (supplyItemLength?.[tabCharacter] || 0) === 0;
  }

  handlePrizeClick(item: IPrize, hiddenTitle = true, preview = false) {
    this.destinyRewardRef?.playAnimation({
      items: [
        {
          original_chance:item.original_chance,
          detail:item.detail,
          recycle_status: item.recycle_status,
          character: item.character,
          product_id: item.product_id,
          product_bazaar_price: item.product_bazaar_price,
          product_price: item.product_price,
          id: item.id,
          title: item.title,
          cover: item.cover,
          price: item.price,
          chance: item.chance,
          supply_type: item.supply_type,
          level: item.character,
          pay_type: true,
          point: 0,
          type: item.blind_supply_type,
        },
      ],
      test: false,
      option: false,
      hiddenTitle,
      preview,
      spc: true,
    });
  }

  async handleSynthetic() {
    this.tips = false;
    const toast = Toast.loading({
      mask: true,
      loadingType: "spinner",
      overlay: true,
      duration: 0,
      className: "mini-loading",
    });
    const { select } = this.currentSelect;
    let data:number[] = []
    select.forEach((row) => {
      const [type, id] = row.split("-").map(Number);
      if (type === 2) data.push(id);
      else data.push(id);
    });
    const { id, token } = await syntheticUpgrade(
      data
    ).catch((err: Error) => {
      toast.close();
      this.messageTip.visible(err.message);
      throw err;
    });
    const result = await this.handleCheckStatus(token);
    InteractionLib.updateGoldBlindBoxStatus()

    toast.close();
    if (!result) {
      this.messageTip.visible("我被榨干了, 请稍后重试!");
      return;
    }
    let box = this.previewData.find((row) => row.id === id);
    if (!box) {
      await this.getPrize();
      this.previewData = cloneDeep(this.prizeItems).filter(
        (row) => row.need_character === Number(this.currentSelect.character)
      );
    }
    await this.synFateRef.play(id, () => {
      this.synFateRef.show = false;
      this.supplyGroupsRef.map((row) => row.clear());
      this.handlePrizeClick(box!, false, true);
      this.getMineSupply();
      this.currentSelect = {
        select: [] as string[],
        character: -1,
        need_num: 0,
      };
    });
  }

  async handleCheckStatus(token: string): Promise<boolean> {
    if (!token) return true;
    const status = await VoucherApi.offerStatus(token);
    if (status === VoucherRecordStatus.complete) {
      return true;
    } else if (status === VoucherRecordStatus.failure) {
      return false;
    }
    await delay(500);
    return this.handleCheckStatus(token);
  }

  handleSelectData({
    select: newSelect,
    character: newCharacter,
    need_num,
  }: SelectData) {
    const { select, character } = this.currentSelect;
    const curr = Number(newCharacter);
    if (this.tabCharacter !== curr) {
      this.active = curr+1;
      document.body.scrollTo(0, 0);
    }
    if (!select.length || character === newCharacter) {
      this.currentSelect = {
        select: newSelect,
        character: newCharacter,
        need_num,
      };
      return;
    }
    Toast("请选择相同品质的补给");
  }

  created() {
    setBackgroundColor("#f2f2f2");
  }

  mounted() {
    observer("refreshInit", () => {
      this.init();
    });
    this.init();
  }

  async getPrize() {
    this.prizeItems = await VoucherApi.getPrize();
    const character = uniq(this.prizeItems?.map((item) => item.need_character));
    this.showCharacter = character;
    this.category = this.category.map((item) => ({
      ...item,
      show: item.key === -1 ? true : character.includes(item.key),
    }));
  }

  async getMineSupply() {
    const supply = await getMineSupply();
    this.supplyLength = supply.length;
    const items = groupBy<IMineSupply>(supply, (row) => row.character.value);
    this.supplyItems = items;
    this.supplyItemLength = Object.assign(
      {},
      ...map(items, (row, key) => ({ [Number(key)]: row.length }))
    );
  }

  async init() {
    this.loading = true;
    await Promise.all([this.getPrize(), this.getMineSupply()]);
    this.loading = false;
  }
}
