






















import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";

@Component({})
export default class PrizeImage extends Vue {
  @Ref("__prize_image")
  private readonly currentRef!: Element;

  @Prop({ default: 1 })
  private readonly character!: number;

  @Prop({ default: "" })
  private readonly cover!: string;

  @Prop({ default: "64px" })
  private readonly wideHigh!: string;

  @Prop({ default: false })
  private readonly hiddenSide!: boolean | string;

  @Prop({ default: false })
  private readonly showSelect!: boolean | string;

  @Prop({ default: false })
  private readonly select!: boolean | string;

  @Prop({ default: false })
  private readonly selectFull!: boolean | string;

  @Prop({ default: "" })
  private readonly tag!: string;

  get isMark() {
    const { selectFull, select } = this;
    return selectFull !== false && select !== true;
  }

  get rootStyle() {
    const { character, wideHigh, hiddenSide } = this;
    return {
      "--character-color": `var(--card-color-${character})`,
      "--wide-high": `${wideHigh}`,
      "--border-left-width": `${hiddenSide !== false ? "4px" : "0px"}`,
    };
  }

  @Emit("click")
  handleClick() {
    return;
  }

  mounted() {}
}
