














































import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";
import PrizeImage from "@/views/synthetic-v3/components/PrizeImage.vue";
import { cloneDeep } from "lodash";
import { SelectData } from "@/views/synthetic-v3/type";
import VirtualList from "vue-virtual-scroll-list";
import { IMineSupply, VoucherApi } from "@/api/voucher.api";
import { TYPE_TEXT } from "@/constant";

@Component({
  components: { PrizeImage, VirtualList },
})
export default class SupplyGroup extends Vue {
  @Ref("__supply_group_body")
  private readonly bodyRef!: HTMLDivElement;

  @Prop({ default: 1 })
  private readonly character!: number;

  @Prop({ default: () => [] })
  private readonly items!: IMineSupply[];

  @Prop({ default: -1 })
  private readonly showCharacter!: number;

  @Prop({ default: () => [] })
  private readonly showCharacterList!: number[];

  @Prop({ default: -1 })
  private readonly notDisabledCharacter!: number;

  private wideHigh = 0;

  private select: string[] = [];

  private need_num = 0;

  private is_synthetic = false;

  get show() {
    return (
      this.showCharacter === -1 || this.showCharacter === Number(this.character)
    );
  }

  get fillStatus() {
    return this.select.length === this.need_num;
  }

  get inadequate() {
    return this.items.length >= this.need_num;
  }

  get characterTag() {
    return TYPE_TEXT[this.character] || "未知";
  }

  get rootStyle() {
    const { character } = this;
    return {
      "--character-color": `var(--card-color-${character})`,
    };
  }

  @Emit()
  private handleSelectData(): SelectData {
    const { select, character, need_num } = this;
    return { select: cloneDeep(select), character, need_num };
  }

  get isDisabled() {
    return ![-1, this.character].includes(this.notDisabledCharacter);
  }

  public clear() {
    this.select = [];
  }

  handleFill() {
    if (this.isDisabled) return;
    if (this.fillStatus) {
      this.select = [];
      return this.handleSelectData();
    }
    const select = cloneDeep(this.select);
    const waitItem = this.items.filter(
      (item) => !select.includes(`${item.supply_type}-${item.id}`)
    );

    const keys = waitItem
      .slice(0, this.need_num - select.length)
      .map((item) => `${item.supply_type}-${item.id}`);
    select.push(...keys);
    this.select = select;
    this.handleSelectData();
  }

  handleSelect(item: IMineSupply) {
    if (this.isDisabled) return;
    const select = cloneDeep(this.select);
    const key = `${item.supply_type}-${item.id}`;
    const index = select.findIndex((row) => row === key);
    if (this.fillStatus && index === -1) {
      return;
    }
    if (index !== -1) {
      select.splice(index, 1);
    } else {
      select.push(key);
    }
    this.select = select;
    this.handleSelectData();
  }

  async handleNeedNumber() {
    const result = await VoucherApi.getLoopInfo(this.character).catch(
      () => undefined
    );
    this.is_synthetic = !!result;
    this.need_num = result?.need_num || 0;
  }

  mounted() {
    this.calculate();
    this.handleNeedNumber();
  }

  calculate() {
    if (!this.bodyRef) return;
    const { clientWidth, offsetWidth } = this.bodyRef;
    const body_w = clientWidth || offsetWidth;
    this.wideHigh = body_w / 3 - 6;
  }
}
