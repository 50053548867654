




























import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import DestinyFrame from "@/views/destiny-draw/components/DestinyFrame.vue";
import DestinyCartoon from "@/views/destiny-draw/components/destinyFate/DestinyCartoon.vue";
import { IPrize } from "@/api/voucher.api";

@Component({
  components: { DestinyCartoon, DestinyFrame },
})
export default class SynFate extends Vue {
  @Ref("destiny-cartoon")
  private readonly destinyCartoonRef!: DestinyCartoon;

  @Prop({ default: () => [] })
  private readonly items!: Array<IPrize>;

  public show = false;

  private get cartoon() {
    return this.items.map((row) => ({
      id: row.id,
      title: row.title,
      cover: row.cover,
      price: row.price,
      chance: row.chance,
      level: row.character,
      pay_type: false,
      point: 0,
      type: 0,
    }));
  }

  public async handleSkip() {
    this.destinyCartoonRef?.stopAnimation();
  }

  public async play(id: number, complete: () => void) {
    this.show = true;
    await this.$nextTick();
    // await delay(300);
    this.destinyCartoonRef?.playAnimation(id, () => {
      complete();
      this.handleSkip();
    });
  }
}
