













import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class DestinyFrame extends Vue {
  @Prop({ default: "" })
  private readonly className!: any;

  @Prop({ default: "" })
  private readonly id!: string;

  get uid() {
    if (!this.id) return "frame_" + Date.now();
    return this.id;
  }
}
