



















import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import PrizeImage from "@/views/synthetic-v3/components/PrizeImage.vue";

type ItemType = { cover: string; character: number } & Record<string, any>;

@Component({
  components: { PrizeImage },
})
export default class PrizeGroup extends Vue {
  @Ref("prize_box")
  private readonly prizeBox!: Element;

  @Prop({ default: "50px" })
  private readonly top!: string;

  @Prop({ default: () => [] })
  private readonly items!: ItemType[];

  @Prop({ default: -1 })
  private readonly showCharacter!: number;

  @Watch("showCharacter")
  watchShowCharacter() {
    this.prizeBox.scrollTo(0, 0);
  }

  @Emit("click")
  private handleClick(item: ItemType) {
    return item;
  }

  get rootStyle() {
    const { top } = this;
    return {
      top,
      marginTop: top,
    };
  }
}
