



























import { Component, Vue } from "vue-property-decorator";
import { Popup } from "vant";

@Component({
  components: {
    Popup,
  },
})
export default class MessageTip extends Vue {
  private message: string[] = [];
  private show = false;
  private title = "";

  public visible(...message: string[]) {
    this.title = "";
    this.message = message;
    this.show = true;
  }

  public visibleTitle(title: string, ...message: string[]) {
    this.title = title;
    this.message = message;
    this.show = true;
  }

  public hidden() {
    this.show = false;
    // this.$nextTick(() => this.message = []);
  }
}
