







































import {
  Component,
  Prop,
  Vue,
  Model,
  Emit,
  Watch,
} from "vue-property-decorator";
import DestinyCard from "@/views/destiny-draw/components/newDestinyCard/NewDestinyCard.vue";
import { throttle } from "lodash";
import { IPrize } from "@/api/voucher.api";
import { getChance, getChanceCause } from "@/utils/common.util";
import { Popup, Icon } from "vant";

@Component({
  components: { DestinyCard, Popup, Icon },
})
export default class SynthesisTip extends Vue {
  @Prop({ default: () => [] })
  private readonly items!: Array<IPrize>;

  @Model("update:model", { default: false })
  private model!: boolean;

  cardWideHigh = 120;
  throttleHandleSynthetic = throttle(this.handleSynthetic.bind(this), 1000, {
    leading: true,
  });

  @Emit("click")
  handleSynthetic() {}

  get tips() {
    return this.model;
  }

  set tips(status: boolean) {
    this.updateModel(status);
  }

  @Emit("update:model")
  updateModel(status: boolean) {
    return status;
  }

  get cardList() {
    const total_chance = this.items
      .map((item) => item.chance)
      .reduce((p, c) => p + c, 0);
    return this.items.map((row) => {
      return {
        id: row.id,
        cover: row.cover,
        character: row.character,
        title: row.title,
        price: row.price / 100,
        rate: `爆率 ${getChanceCause(row.chance, total_chance)}%`,
        type: row.type,
        product_price: row.product_price,
        product_bazaar_price: row.product_bazaar_price,
      };
    });
  }

  async calculativeWidth() {
    await this.$nextTick();
    const {
      clientWidth = 0,
      offsetWidth = 0,
      scrollWidth = 0,
    } = document.querySelector<HTMLDivElement>(".__popup_content") || {};
    const width = (clientWidth || offsetWidth || scrollWidth) - 30;
    return width / 3 - 8;
  }

  @Watch("model")
  async watchModel(model: boolean) {
    if (!model) return;
    this.cardWideHigh = await this.calculativeWidth();
  }
}
